import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Typography, IconButton,Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ConnectionDelay, ConnectionOff, ConnectionOn, Logout } from '../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { emptyUserDetails, emptyUserLogin, OpenLeftDrawer, setStatusServer } from '../../../config/redux/actions';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box } from '@mui/system';
import { apiUrl } from '../../../config';


const TopBar = () => {
    const {openDrawer} = useSelector(state=>state.homeReducers);
    const dispatch = useDispatch();
    const handleClickDrawer = () =>{
        dispatch(OpenLeftDrawer(!openDrawer));
        dispatch(emptyUserDetails());
    }
    const history = useHistory();
    const handleClickLogOut = () =>{
        dispatch(emptyUserLogin());
        Cookies.remove('u_name');
        Cookies.remove('u_tkn');
        history.push('/login');
    }
    // const [statusNetwork,setStatusNetwork] = useState(()=>{
    //     if(navigator.onLine){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // })
    const paramUrl = `${apiUrl.testUrl}`;
    // const {statusServer}=useSelector(state=>state.homeReducers);
    const [messagePing,setMessageping]=useState();
    const checkPingSpeed = useCallback(async ()=>{
        try {
            const startTime = new Date().getTime();
            await fetch(paramUrl,{method:'HEAD',mode:'cors'})
            const endTime = new Date().getTime();
            const responseTime = endTime-startTime;
            return responseTime;
        } catch (error) {
            return "false";
        }
    })
    // const checkOnlineStatus = useCallback(async () =>{
    //     try{
    //         const online = await fetch(paramUrl,{method:'HEAD',mode:'cors'})
    //         return online.status >= 200 && online.status < 300;
    //     }catch(err){
    //         return false;
    //     }
    // },[paramUrl])
    useEffect(()=>{
        // setInterval(async ()=>{
        //     const result = await checkOnlineStatus();
        //     if(result === true){
        //         setStatusNetwork(true);
        //         dispatch(setStatusServer(true));
        //     }else{
        //         setStatusNetwork(false);
        //         dispatch(setStatusServer(false));        
        //     }
        // },500)
        setInterval(async()=>{
            const resultTime = await checkPingSpeed();
            if(resultTime<1000){
                dispatch(setStatusServer(true));
            }else if(resultTime>1000){
                dispatch(setStatusServer(false));
            }
            setMessageping(resultTime);
        },1000)
    },[dispatch])
    return (
        <AppBar position='fixed'>
            <Box sx={{display:'flex',backgroundColor:'red'}}>
                <Box flexShrink={0} sx={{paddingLeft:'20px',}}>
                    <IconButton edge="start" color="inherit" aria-label="menu"onClick={handleClickDrawer}>
                        <MenuIcon/>
                    </IconButton>
                </Box>
                <Box sx={{width:'100%',display:'flex'}}>
                <Typography variant='h5' pl={1} sx={{color:'yellow'}}>Ayam Kremes Mandalawangi</Typography>
                </Box>
                {/* <Box flexShrink={1} sx={{display:'flex',alignItems:'center',width:'100px'}}>
                {messagePing <= 100 ? <Typography variant='caption' align='right'>Speed : {messagePing}ms</Typography> : <Typography variant='caption' align='right'>Kecepatan Koneksi Terganggu : {messagePing}ms</Typography>}
                </Box> */}
                <Box flexShrink={1} sx={{display:'flex',alignItems:'center',width:'100px'}}>
                {/* <Typography sx={{color:'black',marginRight:'5px'}}>Server</Typography> */}
                {/* {statusNetwork === true ? <Typography sx = {{color:'black',marginRight:'5px'}}>Online</Typography>:
                <Typography sx = {{color:'red',marginRight:'5px'}}>Offline</Typography>} */}
                {messagePing <= 100? 
                <Typography sx = {{color:'black'}}>Online <img src={ConnectionOn} alt="on" style={{width:'15px',height:'15px'}}/></Typography> :
                messagePing>100 && messagePing<1000 ? <Typography sx = {{color:'black'}}>Online <img src={ConnectionDelay} alt="idle" style={{width:'15px',height:'15px'}}/></Typography> :
                <Typography sx = {{color:'red'}}>Offline <img src={ConnectionOff} alt="off" style={{width:'15px',height:'15px'}}/></Typography>}
                </Box>
                <Box flexShrink={2} sx={{display:'flex',alignItems:'center'}}>
                <Tooltip title="Keluar">
                <IconButton onClick={handleClickLogOut}>
                    <img src={Logout} alt='logout'/>
                </IconButton>
                </Tooltip>
                </Box>
            </Box>
         </AppBar>
    )
}

export default TopBar
