import { Box, Drawer, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from 'react-redux';
import { OpenLeftDrawer, setClickMenu } from '../../../config/redux/actions/homeActions';
import { CustomerIcon, DaftarHarga, HomeIcon, PembelianIcon, PenjualanIcon,  PPNIcon, ProductIcon, ReturIcon, StokIcon, SupplierIcon, UnitIcon, UserIcon } from '../../assets';
import { MenuList } from '../../molecules';
import {Link, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import { emptyFormDraft, emptyFormPenjualan, emptyPembelianForm, emptyUserDetails, getDetailsPengguna } from '../../../config/redux/actions';

const LeftMenu = () => {
    const {openDrawer,clickMenu} = useSelector(state=>state.homeReducers);
    const dispatch = useDispatch();
    const StyledMenuList = styled(MenuList)(
        ` 
          &:hover, &.Mui-focusVisible {
            background-color: #82141b
          }`
        );
      
    const handleClick = ()=>{
        dispatch(OpenLeftDrawer(!openDrawer));
    }
    const history = useHistory();
    const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    const [uid,setUid]  = useState(dataLogin.u_name);
    const [token,setToken] = useState(dataLogin.token);
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [Retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);

    if(token ===''){
        setToken(Cookies.get('u_tkn'));
        setUid(Cookies.get('u_name'));
    }
    useEffect(()=>{
        if(detailsPengguna===''){
            dispatch(getDetailsPengguna(uid,token,"",history));
        }
        detailsPengguna.m_pengguna === 'true' ? setPengguna(false) : setPengguna(true);
        detailsPengguna.m_pelanggan === 'true' ? setPelanggan(false) : setPelanggan(true);
        detailsPengguna.m_distributor === 'true' ? setDistributor(false) : setDistributor(true);
        detailsPengguna.m_stokbarang === 'true' ? setStokBarang(false) : setStokBarang(true);
        detailsPengguna.m_barang === 'true' ? setBarang(false) : setBarang(true);
        detailsPengguna.m_harga === 'true' ? setHarga(false) : setHarga(true);
        detailsPengguna.m_pembelian === 'true' ? setPembelian(false) : setPembelian(true);
        detailsPengguna.m_penjualan === 'true' ? setPenjualan(false) : setPenjualan(true);
        detailsPengguna.m_retur === 'true' ? setRetur(false) : setRetur(true);
        detailsPengguna.m_laporan === 'true' ? setLaporan(false) : setLaporan(true);
    },[dispatch,uid,token,detailsPengguna])

    const handleClickHalamanUtama = ()=>{
        dispatch(setClickMenu(""));
        dispatch(emptyUserDetails());
    }
    const handleClickPembelian = ()=>{
        dispatch(setClickMenu("pembelian"));
        dispatch(emptyPembelianForm());
        history.push('/pembelian')
    }
    const handleClickPenjualan = ()=>{
        dispatch(setClickMenu("penjualan"));
        dispatch(emptyFormDraft());
        dispatch(emptyFormPenjualan());
        history.push('/penjualan');
    }
    // console.log(window.location.pathname.split('/')[1]);
    useEffect(()=>{
        dispatch(setClickMenu(window.location.pathname.split('/')[1]));
    },[dispatch])
    return (
        <Drawer
        variant='temporary'
        open={openDrawer}
        onClick={handleClick}
        ModalProps={{keepMounted:true}}
         >
             <Box sx={{width:'250px',height:'100vh',backgroundColor:'#de2626',paddingTop:'5px'}}>
                {clickMenu!=="" ?
                <Link to='/' style={{textDecoration:'none',color:'black'}}>
                    <StyledMenuList disabled={false} label="Halaman Utama" icon={HomeIcon} alt="dashboard" handleClick={handleClickHalamanUtama}/>
                </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Halaman Utama" icon={HomeIcon} alt="dashboard"/>
                }
                <Typography pt={1} pl={1} variant='h6' color="whitesmoke">Master Data</Typography>
                {clickMenu!=="pengguna" ?
                <Link style={{textDecoration:"none",color:'black'}} to={Pengguna === false ? "/pengguna" :"/"}>
                    <StyledMenuList disabled={Pengguna} label="Pengguna" icon={UserIcon} alt="userMenu" handleClick={()=>{dispatch(setClickMenu("pengguna"))}} />
                </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pengguna" icon={UserIcon} alt="userMenu" />
                }
                {clickMenu!=="distributor"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Distributor === false ? "/distributor" :"/"}>
                        <StyledMenuList disabled={Distributor} label="Distributor" icon={SupplierIcon} alt="supplierMenu" handleClick={()=>{dispatch(setClickMenu("distributor"))}}/>
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#82141b'}} label="Distributor" icon={SupplierIcon} alt="supplierMenu"/>
                 }
                 {clickMenu!=="pelanggan"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Pelanggan === false ? "/pelanggan" :"/"}>
                        <StyledMenuList disabled={Pelanggan} label="Pelanggan" icon={CustomerIcon} alt="customerMeru" handleClick={()=>dispatch(setClickMenu("pelanggan"))}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pelanggan" icon={CustomerIcon} alt="customerMeru"/>
                }
                {clickMenu!=="barang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/barang" :"/"}>
                        <StyledMenuList disabled={Barang} label="Barang" icon={ProductIcon} alt="productMenu" handleClick={()=>dispatch(setClickMenu("barang"))}/>
                    </Link>
                    : <StyledMenuList style={{backgroundColor:'#82141b'}} label="Barang" icon={ProductIcon} alt="productMenu"/>
                }
                {clickMenu!=="hargabarang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Harga === false ? "/hargabarang" :"/"}>
                        <StyledMenuList disabled={Harga} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu" handleClick={()=>dispatch(setClickMenu("hargabarang"))}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu"/>
                }
                {clickMenu!=="satuan"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/satuan" :"/"}>
                        <StyledMenuList disabled={Barang} label="Satuan" icon={UnitIcon} alt="unitMenu" handleClick={()=>dispatch(setClickMenu("satuan"))}/>
                    </Link>
                :  <StyledMenuList style={{backgroundColor:'#82141b'}} label="Satuan" icon={UnitIcon} alt="unitMenu"/>
                }
                {clickMenu!=="pembelian" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Pembelian === false ? "/pembelian" :"/"}>
                        <StyledMenuList disabled={Pembelian} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu" handleClick={()=>handleClickPembelian()}/>
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu"/>
                }
                {clickMenu!=="stokbarang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={StokBarang === false ? "/stokbarang" :"/"}>
                        <StyledMenuList disabled={StokBarang} label="Stok Barang" icon={StokIcon} alt="stokMenu" handleClick={()=>dispatch(setClickMenu("stokbarang"))}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Stok Barang" icon={StokIcon} alt="stokMenu"/>
                }
                {clickMenu!=="penjualan" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Penjualan === false ? "/penjualan" : "/"}>
                        <StyledMenuList disabled={Penjualan} label="Penjualan" icon={PenjualanIcon} alt="penjualanMenu" handleClick={()=>handleClickPenjualan()}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Penjualan" icon={PenjualanIcon} alt="penjualanMenu"/>
                }
                {clickMenu!=="stokretur" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Retur === false ? "/stokretur" : "/"}>
                        <StyledMenuList disabled={Retur} label="Stok Retur" icon={ReturIcon} alt="returMenu" handleClick={()=>dispatch(setClickMenu("stokretur"))}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Stok Retur" icon={ReturIcon} alt="returMenu"/>
                }
                {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="ppn" ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/ppn'>
                    <StyledMenuList disabled={Laporan} label="PPN" icon={PPNIcon} alt="ppnMenu" handleClick={()=>(dispatch(setClickMenu("ppn")))}/>
                </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="ppn" ?
                 <StyledMenuList style={{backgroundColor:'#82141b'}} label="PPN" icon={PPNIcon} alt="ppnMenu"/>
                 :null}
                {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="laporan" ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/laporan'>
                    <StyledMenuList disabled={Laporan} label="Laporan" icon={DaftarHarga} alt="laporanMenu" handleClick={()=>(dispatch(setClickMenu("laporan")))}/>
                </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="laporan" ? 
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Laporan" icon={DaftarHarga} alt="laporanMenu" /> 
                : null}
            </Box>
        </Drawer>
    )
}

export default LeftMenu
